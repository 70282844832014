<template>

  <b-row>
    <b-col
      cols="12"
      class="form_custom"
    >
      <b-card-code title="">
        <b-row>
          <b-col cols="3">
            <b-button
              variant="outline-danger"
              class="position-absolute position-bottom-0 btn-sm"
              :disabled="!ids.length"
              @click="deleteMulti"
            >
              <feather-icon
                icon="Trash2Icon"
                class="mr-50"
              />
              <span class="align-middle">{{ $t('Delete') }}</span>
            </b-button>
          </b-col>
          <b-col cols="3" />
          <b-col md="6">
            <label for="">
              {{ $t("Search") }}
            </label>
            <b-form-input
              v-model="filter.key"
              :placeholder="$t('search_key')"
              @change="loadItems"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <table
              class="table b-table"
              style="margin-top: 10px"
            >
              <thead>
                <tr>
                  <th width="10">
                    <b-form-checkbox
                      class="custom-control-danger"
                      name="check-button"
                      @change="val => toggleSelectAll(val)"
                    />
                  </th>
                  <th
                    scope="col"
                    width="10"
                  >
                    STT
                  </th>
                  <th
                    scope="col"
                  >
                    {{ $t("name_q") }}
                  </th>
                  <th
                    scope="col"
                    width="300"
                  >
                    {{ $t("email") }}
                  </th>
                  <th
                    scope="col"
                    width="200"
                  >
                    Đề thi/bài tập
                  </th>
                  <th
                    scope="col"
                    width="200"
                    class="text-center"
                  >
                    Kết quả
                  </th>
                  <th
                    scope="col"
                    width="100"
                  >
                    Thời gian làm bài
                  </th>
                  <th
                    width="100"
                    scope="col"
                    :aria-sort="getSortDirection('created_at')"
                    @click="changeSortDirection('created_at')"
                  >
                    Ngày thi
                  </th>
                  <th
                    width="10"
                    scope="col"
                  >
                    {{ $t('option') }}
                  </th>
                </tr>
              </thead>
              <template v-if="isLoading">
                <tr
                  v-for="(item) in rows"
                >
                  <td colspan="1">
                    <b-skeleton animation="fade" />
                  </td>
                  <td colspan="6">
                    <b-skeleton animation="fade" />
                  </td>
                  <td colspan="2">
                    <b-skeleton animation="fade" />
                  </td>
                </tr>
              </template>
              <tr
                v-for="(item, index) in rows"
                v-else
                :id="item.id"
                :key="item.id"
              >
                <td>
                  <b-form-checkbox
                    v-model="ids"
                    :value="item.id"
                    class="custom-control-danger"
                    name="check-button"
                  />
                </td>
                <td>
                  {{ index + 1 }}
                </td>
                <td>
                  <div class="d-flex align-items-center">

                    <router-link
                      v-if="item.lesson.type_exercise === 1"
                      class="text-black"
                      :to="{ name: 'exam-exercise-detail', params: { id: item.id, data: item, account: item.user}}"
                    >
                      <b-card-text v-if="item.user">
                        {{ item.user.name }}
                      </b-card-text>
                      <b-card-text v-else>
                        {{ item.name }}
                      </b-card-text>
                    </router-link>
                    <router-link
                      v-else
                      class="text-black"
                      :to="{ name: 'exam-result-detail', params: { id: item.id, account: item.user }}"
                    >
                      <b-card-text v-if="item.user">
                        {{ item.user.name }}
                      </b-card-text>
                      <b-card-text v-else>
                        {{ item.name }}
                      </b-card-text>
                    </router-link>
                  </div>
                </td>
                <td>
                  <b-card-text v-if="item.user">
                    {{ item.user.email }}
                  </b-card-text>
                  <b-card-text v-else>
                    {{ item.email }}
                  </b-card-text>
                </td>
                <td>
                  <b-card-text>
                    {{ item.lesson.name }}
                  </b-card-text>
                </td>
                <td class="text-center">
                  <b-badge
                    v-if="item.is_complete"
                    variant="success"
                  >
                    {{ item.total_point }}
                  </b-badge>
                  <b-badge
                    v-else
                    variant="warning"
                  >
                    {{ item.total_point }}
                  </b-badge>
                </td>
                <td>{{ convertSecondsToHMS(item.time_remain/1000) }}</td>
                <td> {{ convertDate(item.created_at) }} </td>
                <td>
                  <b-dropdown
                    id="dropdown-1"
                    class="custom-position-dropdown"
                    variant="flat-sencondary"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle"
                      />
                    </template>
                    <div
                      class="dropdown-item"
                    >
                      <div>
                        <router-link
                          class="text-black"
                          :to="{ name: 'exam-result-detail', params: { id: item.id}}"
                        >
                          <svg
                            id="invoice-row-5033-preview-icon"
                            data-v-5cb60a6f=""
                            xmlns="http://www.w3.org/2000/svg"
                            width="16px"
                            height="16px"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            class="mx-1 feather feather-eye"
                          ><path
                            data-v-5cb60a6f=""
                            d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                          /><circle
                            data-v-5cb60a6f=""
                            cx="12"
                            cy="12"
                            r="3"
                          /></svg>
                          Xem
                        </router-link>
                      </div>
                    </div>
                  </b-dropdown>
                </td>
              </tr>
            </table>
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="pageLength"
              first-number
              last-number
              class="pagination-danger mt-2"
              align="right"
              @input="onPageChange"
            >
              <template #prev-text>
                <feather-icon
                  size="18"
                  icon="ChevronLeftIcon"
                />
              </template>
              <template #next-text>
                <feather-icon
                  size="18"
                  icon="ChevronRightIcon"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </b-card-code>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'

import {
  BRow,
  BCol,
  BButton,
  VBModal,
  BFormInput,
  BOverlay,
  BPagination,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BListGroup,
  BForm,
  BImg,
  BFormFile,
  BModal,
  BFormRadio,
  BInputGroupAppend,
  BBadge,
  BListGroupItem,
  BFormSelect,
  BFormTextarea,
  BEmbed,
  BCardText,
  VBTooltip,
  BSkeleton,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import { required } from '@validations'
import config from '@/config'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    ValidationProvider,
    BSkeleton,
    ValidationObserver,
    BCardCode,
    BImg,
    vSelect,
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    quillEditor,
    BCol,
    BCardText,
    draggable,
    BFormSelect,
    BEmbed,
    BFormFile,
    BFormGroup,
    BForm,
    BButton,
    BFormInput,
    BOverlay,
    BPagination,
    BFormCheckbox,
    BFormRadio,
    BInputGroupAppend,
    BDropdown,
    // eslint-disable-next-line vue/no-unused-components
    BDropdownItem,
    BListGroup,
    BListGroupItem,
    // eslint-disable-next-line vue/no-unused-components
    BModal,
    BBadge,
    BFormTextarea,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      currentPage: 1,
      pageLength: 10,
      isShow: true,
      ids: [],
      isLoading: false,
      total: 0,
      rows: [],
      endPoint: process.env.VUE_APP_API_ENDPOINT,
      filter: {
        key: '',
      },
      sort: {
        by: 'order',
        direction: 'desc',
      },

    }
  },
  created() {
    this.loadItems()
  },
  methods: {
    deleteMulti(id) {
      this.$swal({
        title: this.$t('message.Confirm_delete'),
        text: false,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true
          if (typeof id === 'number') this.ids = [id]
          this.$http.delete('/home-work-history', {
            data: {
              ids: this.ids,
              type: 1,
            },
          })
          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.$bvToast.toast(this.$t('message.delete_success'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'success',
                solid: false,
              })
              this.loadItems()
              // eslint-disable-next-line no-unused-vars
            }).catch(e => {
              this.$bvToast.toast(this.$t('message.delete_err'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'danger',
                solid: false,
              })
            }).finally(() => {
              this.isLoading = false
              this.ids = []
            })
        }
      })
    },
    toggleSelectAll(value) {
      if (value) {
        this.ids = this.rows.map(v => v.id)
      } else {
        this.ids = []
      }
    },
    onPageChange(currentPage) {
      this.currentPage = currentPage
      this.loadItems()
    },
    loadItems() {
      this.isLoading = true
      this.$http.get('/home-work-history', {
        params: {
          perPage: this.pageLength,
          page: this.currentPage,
          key: this.filter.key,
          order_by: this.sort.by,
          order_direction: this.sort.direction === 'ascending' ? 'asc' : 'desc',
        },
      })
        .then(res => {
          this.total = res.data.total
          this.pageLength = res.data.per_page
          this.rows = res.data.data.map(v => {
            // eslint-disable-next-line no-param-reassign
            v.status = v.status === 1
            return v
          })
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },
    getSortDirection(sortBy) {
      if (sortBy === this.sort.by) {
        return this.sort.direction
      }

      return 'none'
    },
    convertSecondsToHMS(seconds) {
      const date = new Date(null)
      date.setSeconds(seconds)

      return date.toISOString().substr(11, 8)
    },
    changeSortDirection(sortBy) {
      if (this.sort.by !== sortBy) {
        this.sort.direction = 'none'
        this.sort.by = sortBy
      }
      if (this.sort.direction === 'none') {
        this.sort.direction = 'ascending'
      } else if (this.sort.direction === 'ascending') {
        this.sort.direction = 'descending'
      } else {
        this.sort.by = 'order'
        this.sort.direction = 'desc'
      }
      this.loadItems()
    },
    convertDate(iosDate) {
      if (!iosDate) return ''
      const date = new Date(iosDate)
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let dt = date.getDate()

      if (dt < 10) {
        dt = `0${dt}`
      }
      if (month < 10) {
        month = `0${month}`
      }

      return `${dt}/${month}/${year}`
    },
  },
}
</script>
